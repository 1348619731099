<template>
<div>
  <editor :value="content" @input="tinymcechange" api-key="no-api-key" :init="config()" />
    <ImageManage ref="imageManager" :preview="false" v-model="imageList" :imageLength="-1"></ImageManage>
</div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
import ImageManage from '@/components/imageManage/index.vue'

export default {
  props: ['content'],
  components: { Editor, ImageManage },
  data () {
    return {
      imageList: [],
      myEditor: null,
      svgHtml: '<svg width="24" height="24" focusable="false"><path d="M5 15.7l3.3-3.2c.3-.3.7-.3 1 0L12 15l4.1-4c.3-.4.8-.4 1 0l2 1.9V5H5v10.7zM5 18V19h3l2.8-2.9-2-2L5 17.9zm14-3l-2.5-2.4-6.4 6.5H19v-4zM4 3h16c.6 0 1 .4 1 1v16c0 .6-.4 1-1 1H4a1 1 0 01-1-1V4c0-.6.4-1 1-1zm6 8a2 2 0 100-4 2 2 0 000 4z" fill-rule="nonzero"></path></svg>'

    }
  },
  watch: {
    imageList (newVal) {
      if (newVal && typeof newVal === 'object') {
        this.save(newVal)
      }
    }
  },
  methods: {
    save (newVal) {
      let self = this
      let htmlString = ''
      newVal.forEach(element => {
        htmlString += '<img style="max-width:100%;" src="' + element + '" />'
      })
      self.myEditor.insertContent(htmlString)
    //   self.myEditor.ui.show()
      //   tinymce.activeEditor.theme.panel.find('toolbar *')[27].active(true)
    },
    config () {
      let self = this
      let configObj = Object.assign(
        {
          language_url: 'https://static.chamagujiao.net/js/tinymce/langs/zh_CN.js', // 语言包的路径
          deprecation_warnings: false,
          width: 375,
          height: 800,
          menubar: false, // 顶部菜单栏是否显示
          language: 'zh_CN',
          content_style: 'p {margin: 0px; border:0px ; padding: 0px; line-height:1.8;} img { max-width:100%;}', // 设置行间距
          image_dimensions: false,
          plugins: [
            'advlist autolink lists image axupimgs charmap print preview anchor',
            'searchreplace visualblocks code fullscreen',
            'insertdatetime media table paste code help wordcount'
          ],
          toolbar:
        'code formatselect fontsizeselect lineheight forecolor backcolor bold italic underline strikethrough | alignleft aligncenter alignright alignjustify removeformat| customImageUploader image axupimgs quicklink table numlist bullist preview fullscreen',
          // fontsize_formats: '12px 14px 16px 18px 24px 36px 48px 56px 72px',
          block_formats: 'Paragraph=p;Header 1=h1;Header 2=h2;Header 3=h3',
          lineheight_formats: '1 1.2 1.4 1.8 2 3 4 5',
          style_formats_autohide: true,
          autosave_ask_before_unload: false,
          forced_root_block_attrs: {
            style: 'line-height:1.8' // 强制 使用 p标签 每次都加上默认行高
          },
          extended_valid_elements: 'img[style=max-width:100%; |class|src|border=0|alt|title|hspace|vspace|width|height|align|onmouseover|onmouseout|name]', // 很重要的功能 ··························
          formats: {
            aligncenter: { selector: 'p', styles: { 'text-align': 'center' } }
          },
          toolbar_drawer: false, // 工具栏隐藏更多
          images_upload_handler: function (blobInfo, success, failure) {
            self.uploadFile(blobInfo, success, failure)
          },
          setup: function (editor) {
            self.myEditor = editor
            console.log(self.myEditor, 'self.myEditor')
            editor.ui.registry.addButton('customImageUploader', {
              text: self.svgHtml,
              tooltip: '上传图片',
              onAction: () => {
                self.$refs.imageManager.showModal()
                self.$refs.imageManager.showSelectedImages = []
              }
            })
          }
        }
      )
      return configObj
    },
    async uploadFile (blobInfo, success, failure) {
      /* eslint-disable no-undef */
      let param = new FormData() // 创建form对象
      param.append('file', blobInfo.blob())
      console.log(param.get('file')) // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: this.$store.state.header
      }
      console.log(config, 'headers')
      // 添加请求头
      axios.post(this.$consts.uploadFileUrl, param, config)
        .then(res => {
          console.log(res, 'res')
          if (res.status === 200) {
            success(res.data.result)
          }
        }).catch((error) => {
          if (error) {
            failure('HTTP Error: 上传失败')
          }
        })
    },
    tinymcechange (e) {
      console.log(e, 'eee')
      this.$emit('changeContent', e)
    }
  },
  model: {
    prop: 'content',
    event: 'changeContent'
  }
}
</script>
