<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 15:19:14
 * @LastEditors: 高涛
 * @LastEditTime: 2021-11-16 10:53:17
-->
<template>
  <div class="previewContent">
    <div  v-for="(item,index) in items" :key="index">
      <h1 v-if="item.resourceType==='title'">{{item.content}}</h1>
      <p v-if="item.resourceType==='text'">{{item.content}}</p>
      <img v-if="item.resourceType==='img'" style="width:100%" :src="item.imageUrl" />
      <video v-if="item.resourceType==='mv'" style="width:100%;" controls>
        <source :src="item.mediaUrl" type="video/mp4">
        <!-- <source :src="movie.ogg" type="video/ogg"> -->
        您的浏览器不支持 HTML5 video 标签。
      </video>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      items: []
    }
  },
  created () {
    // this.queryTreeData()
  },
  methods: {
    async init (items) {
      this.items = items
      console.log(items)
    }
  }
}
</script>

<style lang="scss" scoped>
.previewContent{
  height:600px;
  overflow-y: auto;
  h1{
    font-weight: bold;
  }
}
</style>
