<!--
 * @Descripttion:
 * @version:
 * @Author: 高涛
 * @Date: 2021-10-25 14:48:34
 * @LastEditors: 高涛
 * @LastEditTime: 2021-12-23 17:14:36
-->
<template>
  <div id="content">
    <Breadcrumb :breadList="breadList">
    </Breadcrumb>
    <!--  -->
    <a-form-model
      ref="form"
      :model="form"
      :rules="rules"
      layout="horizontal"
      :label-col="{span:4}"
      :wrapper-col="{span:14}"
    >
    <div class="content-area-title">基础信息</div>
      <br/>
      <a-form-model-item label="列表样式" prop="viewStyle">
        <div class="view-style-img-contain">
          <div v-for="(value,key,index) in viewStyleSetting" :key="index" @click="form.viewStyle=value.keycode" class="view-style-img" :class="{'active':form.viewStyle===value.keycode}"><img style="width:90%;" :src="value.imgUrl" /></div>
        </div>
      </a-form-model-item>
      <a-form-model-item></a-form-model-item>
      <a-form-model-item v-if="form.viewStyle!=='' && viewStyleSetting && viewStyleSetting[form.viewStyle] && viewStyleSetting[form.viewStyle]['imgNum']>0"   label="封面图" prop="imageUrl">
        <ImageManage v-model="form.imageUrl" @change="$refs.form.validateField(['imageUrl'])" :square="false" :size="1024*1024*2" :showWidth="true">
            <div slot="extra">建议图片800*450，jpg,png,gif，单张图片大小不超过2MB</div>
        </ImageManage>
      </a-form-model-item>
      <!-- <a-form-model-item v-if="form.viewStyle!=='' && viewStyleSetting && viewStyleSetting[form.viewStyle] && viewStyleSetting[form.viewStyle]['imgNum']>0"   label="封面图" prop="imgLength">
        <div class="clearfix">
          <a-upload
            class="myupload"
            list-type="picture-card"
            :file-list="fileList"
            @preview="handlePreview"
            :action="$consts.uploadFileUrl"
            :headers="$store.state.header"
            :before-upload="beforeUploadMainImg"
            @change="handleChange($event)"
          >
            <div v-if="fileList.length < viewStyleSetting[form.viewStyle]['imgNum']">
              <a-icon type="plus" />
              <div class="ant-upload-text">
                Upload
              </div>
            </div>
          </a-upload>
          <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
         <div slot="extra">建议图片800*450，jpg,png,gif，单张图片大小不超过2MB</div>
      </a-form-model-item> -->
      <a-form-model-item  v-if="form.viewStyle!=='AS'"></a-form-model-item>
      <a-form-model-item label="栏目" prop="idNavigations">
        <a-tree-select
          allow-clear
          multiple
          :replaceFields="{children:'children', title:'name', key:'id', value: 'id' }"
          v-model="form.idNavigations"
          style="width: 100%"
          :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
          :tree-data="treeData"
          treeNodeFilterProp="label"
          placeholder="请选择"
          tree-default-expand-all
        >
          <!-- <span v-if="key === '0-0-1'" slot="name" slot-scope="{ key, value }" style="color: #08c">
            Child Node1 {{ value }}
          </span> -->
        </a-tree-select>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title">
        <a-input placeholder="请输入" v-model="form.title" />
      </a-form-model-item>
      <!-- <a-form-model-item label="简介" prop="intro">
        <a-input placeholder="请输入" v-model="form.intro" />
      </a-form-model-item>
      <a-form-model-item label="作者" prop="author">
        <a-input placeholder="请输入" v-model="form.author" />
      </a-form-model-item> -->

      <!-- <a-form-model-item label="来源" prop="source">
        <a-input placeholder="请输入" v-model="form.source" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="副标题" prop="titleSub">
        <a-input placeholder="请输入" v-model="form.titleSub" />
      </a-form-model-item> -->
      <!-- <a-form-model-item label="是否置顶" prop="top">
        <a-radio-group v-model="form.top">
          <a-radio :value=0>
            是
          </a-radio>
          <a-radio :value=1>
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item> -->
      <!-- <a-form-model-item label="摘要" prop="summary">
        <a-textarea v-model="form.summary" placeholder="请输入" :rows="1"/>
      </a-form-model-item> -->
      <!-- <a-form-model-item label="原创" prop="original">
        <a-radio-group v-model="form.original">
          <a-radio :value=0>
            是
          </a-radio>
          <a-radio :value=1>
            否
          </a-radio>
        </a-radio-group>
      </a-form-model-item> -->

      <!-- <a-form-model-item label="启用" prop="original">
        <a-radio-group v-model="form.releaseStatus">
          <a-radio :value=1>
            发布
          </a-radio>
          <a-radio :value=0>
            不发布
          </a-radio>
        </a-radio-group>
      </a-form-model-item> -->
      <!-- <a-form-model-item label="标签" prop="tagList">
        <a-select mode="tags" v-model="form.tagList" style="width: 100%" :token-separators="[',']">
        </a-select>
      </a-form-model-item> -->
      <!-- <a-form-model-item label="外部链接">
        <a-input  v-model="form.linkUrl" style="width: 100%"  placeholder="请输入" />
      </a-form-model-item> -->
      <div  style="display: none" class="content-area-title">内容主体</div>
      <a-form-model-item label=" " :colon="false" prop="contentConstitute">
        <a-radio-group v-model="form.contentConstitute">
          <a-radio-button value="linkUrl">
            静态链接
          </a-radio-button>
          <a-radio-button value="article_webview">
            富文本
          </a-radio-button>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item v-if="form.contentConstitute==='article_webview'" label="主体内容" :wrapper-col="{ span:16}" prop="content">
        <div style="min-width:800px; display:flex; justify-content:center;">
            <div class="content_review_father">
                <p>文章预览效果</p>
                <iframe class="content_review"></iframe>
            </div>
            <Editor v-model="form.content"></Editor>
          </div>
      </a-form-model-item>

      <a-form-model-item v-show="form.contentConstitute==='linkUrl'" label="链接地址" prop="linkUrl">
        <a-input placeholder="请输入" v-model="form.linkUrl" />
      </a-form-model-item>
      <br/>
      <!-- <a-form-model-item label=" " :colon="false" prop="" :label-col="labelCol">
        asdfdf
      </a-form-model-item> -->

      <div v-show="form.contentConstitute==='article_component'">
        <div  v-for="(item,index) in form.items" :key="index">
          <a-form-model-item :label="itemLabel(item.resourceType)" prop="item">
            <a-space>
              <a-input v-if="item.resourceType==='title'" placeholder="请输入" v-model="item.content" />
              <a-textarea v-if="item.resourceType==='text'" v-model="item.content"  placeholder="请输入" :rows="4"/>
              <div v-if="item.resourceType==='img'">
                <div v-if="item.imageUrl !==''">
                  <img style="width:100%" :src="item.imageUrl" />
                  &emsp;

                </div>
                <a-upload-dragger
                  v-else
                  name="file"
                  :multiple="false"
                  :action="$consts.uploadFileUrl"
                  :headers="$store.state.header"
                  :before-upload="beforeUpload"
                  @change="handleChange3($event,index)"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    点击或将图片拖拽到这里上传
                  </p>
                  <p class="ant-upload-hint">
                    支持扩展名：jpg、png
                  </p>
                </a-upload-dragger>
              </div>
              <div style="width:500px;height:40px;" v-if="item.resourceType==='mv'">
                <div v-if="item.mediaUrl !==''">
                  <a-input style="width:100%;" disabled :value="item.mediaUrl" />
                  &emsp;
                </div>
                <a-upload-dragger
                  v-else
                  name="file"
                  :multiple="false"
                  :action="$consts.uploadFileUrl"
                  :headers="$store.state.header"
                  :before-upload="beforeUpload2"
                  @change="handleChange2($event,index)"
                >
                  <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                  </p>
                  <p class="ant-upload-text">
                    点击或将图片拖拽到这里上传
                  </p>
                  <p class="ant-upload-hint">
                    支持扩展名：mp4
                  </p>
                </a-upload-dragger>
              </div>
              <a-button v-if="item.resourceType ==='mv' || item.resourceType ==='img'" @click="delItemUrl({name:item.resourceType,index})">替换</a-button>
              <span v-else style="padding:0 32px;"></span>
              <a-popconfirm placement="top" ok-text="Yes" cancel-text="No" @confirm="deleteItem(index)">
                <template slot="title">
                  <p>确定要删除吗</p>
                </template>
                <a-button type="danger" >删除</a-button>
              </a-popconfirm>

              <a-button type="primary" :disabled="index===0" @click="(()=>{moveItem({direction:'up',index})})">上移动</a-button>
              <a-button type="primary" :disabled="index===form.items.length-1" @click="(()=>{moveItem({direction:'down',index})})">下移动</a-button>
            </a-space>
          </a-form-model-item>
        </div>
        <a-form-model-item label=" " :colon="false" :wrapper-col="{ style:'width:500px;'}">
          <br/>
          <a-dropdown placement="bottomCenter">
            <a-button block>
              添加内容
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item @click="(()=>{addItem('title')})">
                标题
              </a-menu-item>
              <a-menu-item @click="(()=>{addItem('text')})">
                文本
              </a-menu-item>
              <a-menu-item @click="(()=>{addItem('img')})">
                图片
              </a-menu-item>
              <a-menu-item @click="(()=>{addItem('mv')})">
                视频
              </a-menu-item>
            </a-menu>
          </a-dropdown>

          <a-button @click="showModal(form.items)" block>
            预览
          </a-button>
        </a-form-model-item>
      </div>

      <br/>
      <br/>
      <br/>

      <a-form-model-item label=" " :colon="false">
        <a-button type="primary" @click="onSubmit">
          保存
        </a-button>
        <a-button style="margin-left: 10px;" @click="goback">
          取消
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <!-- 弹窗口 -->
    <a-modal :width="400" :bodyStyle="{'padding':'10px'}" v-model="visible" title="内容预览" @ok="handleOk" :footer="null">
      <ContentPreview  ref="refModal"  @ok="handleOk"></ContentPreview>
    </a-modal>
  </div>
</template>

<script>
import ContentPreview from './preview'
import Breadcrumb from '@/components/breadcrumb.vue'
import Editor from '@/components/tinyMCE.vue'
import ImageManage from '@/components/imageManage/index.vue'
function getBase64 (file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })
}
export default {
  components: {
    Breadcrumb, ContentPreview, Editor, ImageManage
  },
  data () {
    let checkPending
    let checkImageList = (rule, value, callback) => {
      clearTimeout(checkPending)
      checkPending = setTimeout(() => {
        let num1 = this.fileList.length
        let num2 = this.viewStyleSetting[this.form.viewStyle].imgNum
        if (num1 < num2) {
          return callback(new Error('请上传图片'))
        }
        return callback()
      }, 200)
    }
    const validLinkUrl = (rule, value, callback) => {
      if (this.form.contentConstitute === 'linkUrl') {
        if (!value) {
          return callback(new Error('请输入链接地址'))
        }
        if (!(/^http[s]?:\/\/.*/.test(value))) {
          return callback(new Error('请输入正确的链接地址 例如：https://www.xxx.com'))
        }
        callback()
      }
      callback()
    }
    return {
      isClear: false,
      visible: false,
      // icon个数
      // 下方是图片上传
      previewVisible: false,
      previewImage: '',
      fileList: [],
      // 上面是图片上传
      breadList: [
        {
          name: '内容管理',
          url: '/content/list'
        },
        {
          name: '内容编辑',
          url: ''
        }
      ],
      treeData: [],
      wrapperCol2: { style: 'width:734px;' },
      other: '',
      viewStyleSetting: {
        // AS: {
        //   keycode: 'AS',
        //   imgUrl: 'https://images.chamagujiao.net/2021/12/13/1470404240479432705.jpg',
        //   imgNum: 0,
        //   styleName: '文章-标题'
        // },
        // AS1: {
        //   keycode: 'AS1',
        //   imgUrl: 'https://images.chamagujiao.net/2021/12/13/1470404164709330946.jpg',
        //   imgNum: 1,
        //   styleName: '文章-标题+1图'
        // },
        // AS3: {
        //   keycode: 'AS3',
        //   imgUrl: 'https://images.chamagujiao.net/2021/12/13/1470404517089587202.jpg',
        //   imgNum: 3,
        //   styleName: '文章-标题+3图'
        // },
        AS11: {
          keycode: 'AS11',
          imgUrl: 'https://images.chamagujiao.net/2021/12/16/1471368628929654785.jpg',
          imgNum: 1,
          styleName: '文章-标题+1图'
        }
      },
      form: {
        id: '',
        releaseStatus: 0,
        contentType: 'article',
        viewStyle: 'AS11',
        linkUrl: '',
        idNavigations: undefined,
        imageUrl: '',
        imageUrl2: '',
        imageUrl3: '',
        imageUrl4: '',
        author: '',
        title: '',
        source: '',
        titleSub: '',
        top: 0,
        summary: '',
        original: 0,
        intro: '',
        tagList: [],
        contentConstitute: 'linkUrl',
        content: '',
        items: [],
        name: '',
        region: undefined,
        type: [],
        resource: '',
        desc: ''
      },
      rules: {
        imgLength: [{
          required: true, validator: checkImageList, trigger: 'change'
        }],
        imageUrl: [{
          required: true, message: '请上传封面图', trigger: 'change'
        }],
        title: [{
          required: true, message: '请输入标题', trigger: 'blur'
        }],
        idNavigations: [{
          required: true, message: '请选择栏目', trigger: 'change'
        }],
        content: [{
          required: true, message: '请填写内容', trigger: 'blur'
        }],
        linkUrl: [{ validator: validLinkUrl, trigger: 'blur' }]
      }
    }
  },
  created () {
    this.preUrl = `${process.env.VUE_APP_TOP}`
    if (this.$route.query.id) {
      this.getContentInfo()
    }
    this.queryTreeData()
  },
  computed: {
  },
  watch: {
    'form.content': {
      handler (newVal) {
        this.reviewIframe(newVal)
      },
      deep: true
    }
  },
  methods: {
    reviewIframe (htmlStr) {
      let iframe = document.querySelector('iframe')
      if (iframe) {
        let frameWin = document.querySelector('iframe').contentWindow
        let frameDoc = frameWin.document
        let frameBody = frameDoc.body
        var tempCss = ''
        tempCss += '<style type="text/css">'
        tempCss += 'body{width:100%;margin:0;}'
        tempCss += 'img{ max-width:100% !important;}'
        tempCss += 'p{ margin:0;padding:0;border:0px;}'
        tempCss += '</style>'
        frameBody.innerHTML = tempCss + htmlStr
      }
    },
    change (val) {
      console.log(val)
    },
    handleOk (values) {
      console.log(values, this.formState)
      this.visible = false
      this.queryData()
    },
    // 弹出窗口
    showModal (items) {
      this.visible = true
      setTimeout(() => {
        this.$refs.refModal.init(items)
      }, 200)
    },
    async getContentInfo () {
      const res = await this.$store.dispatch('http', {
        api: 'cmsManageContent',
        query: {
          id: this.$route.query.id
        }
      })
      this.form = {
        id: res.id,
        releaseStatus: res.releaseStatus,
        contentType: res.contentType,
        viewStyle: res.viewStyle,
        idNavigations: res.idNavigations,
        imageUrl: res.imageUrl,
        imageUrl2: '',
        imageUrl3: '',
        imageUrl4: '',
        author: res.author,
        title: res.title,
        linkUrl: res.linkUrl,
        source: res.source,
        titleSub: res.titleSub,
        top: res.top,
        summary: res.summary,
        original: res.original,
        intro: res.intro,
        tagList: this.setTags(res),

        contentConstitute: res.contentConstitute,
        content: res.content,
        items: res.items
      }
      //   this.setImg(res)// 设置 图片回示
      this.form.items.reverse() // items返回的是倒序 要倒一下
      console.log(this.form.content, '1111111')
      setTimeout(() => {
        this.reviewIframe(this.form.content)
      }, 500)
    },
    setTags (res) {
      const tag = res.tags ? res.tags.split(',') : []
      return tag
    },
    // setImg (res) {
    //   if (res.imageUrl && res.imageUrl.length > 0) this.fileList.push({ uid: '1', status: 'done', response: { result: res.imageUrl }, url: res.imageUrl, name: 'imgeUrl' })
    //   if (res.imageUrl2 && res.imageUrl2.length > 0) this.fileList.push({ uid: '2', status: 'done', response: { result: res.imageUrl2 }, url: res.imageUrl2, name: 'imgeUrl2' })
    //   if (res.imageUrl3 && res.imageUrl3.length > 0) this.fileList.push({ uid: '3', status: 'done', response: { result: res.imageUrl3 }, url: res.imageUrl3, name: 'imgeUrl3' })
    //   if (res.imageUrl4 && res.imageUrl4.length > 0) this.fileList.push({ uid: '4', status: 'done', response: { result: res.imageUrl4 }, url: res.imageUrl4, name: 'imgeUrl4' })
    // },
    delItemUrl ({ name, index }) {
      console.log(name)
      const name2 = name === 'mv' ? 'mediaUrl'
        : name === 'img' ? 'imageUrl' : ''
      this.form.items[index][name2] = ''
    },
    beforeUpload (file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 < 300
      setTimeout(() => {
        if (!isJpgOrPng) {
          this.$store.dispatch('showToast', { type: 'error', message: '请上传图片格式为jpeg或者png的图片' })
          return false
        }
        if (!isLt2M) {
          this.$store.dispatch('showToast', { type: 'error', message: '图片大小不能超过300KB' })
        }
      }, 200)
      return isJpgOrPng && isLt2M
    },
    beforeUploadMainImg (file) {
      return new Promise((resolve, reject) => {
        var testmsg = /^image\/(jpeg|png|jpg|gif)$/.test(file.type)
        const isOverBit = file.size / 1024 / 1024 <= 2// 图片大小不超过2MB
        if (!testmsg) {
          this.$store.dispatch('showToast', { type: 'error', message: '上传格式不对' })
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject(false)
        }
        if (!isOverBit) {
          this.$store.dispatch('showToast', { type: 'error', message: '上传图片大小不能超过 2M!' })
          // eslint-disable-next-line prefer-promise-reject-errors
          return reject(false)
        }
        return resolve(true)
      })
    },
    beforeUpload2 (file) {
      console.log(file)
      const isMp4 = file.type === 'video/mp4'
      // const isLt2M = file.size / 1024 < 300
      setTimeout(() => {
        if (!isMp4) {
          this.$store.dispatch('showToast', { type: 'error', message: '请上传Mp4格式的视频文件' })
          return false
        }
      }, 200)
      return isMp4
    },
    reSortItems () {
      // 重新排序 items 否则 添加新item 时 sort会错乱
      this.form.items.forEach((item, index) => {
        item.sort = index
      })
    },
    deleteItem (index) {
      this.form.items.splice(index, 1)
      // this.reSortItems()
    },
    moveItem ({ direction, index }) {
      console.log(index)
      let newTwoItems = []
      let items = this.form.items
      if (direction === 'up') {
        newTwoItems.push({ ...items[index] })
        newTwoItems.push({ ...items[index - 1] })
        items.splice(index - 1, 2, ...newTwoItems)
      } else {
        console.log('down')
        newTwoItems.push({ ...items[index + 1] })
        newTwoItems.push({ ...items[index] })
        items.splice(index, 2, ...newTwoItems)
      }
      this.reSortItems() // 重置修改序号
    },
    addItem (str) {
      this.form.items.push({
        resourceType: str,
        content: '',
        imageUrl: '',
        mediaUrl: '',
        sort: this.form.items.length + 1
      })
    },
    changeStyle (e) {
      let value = e.target.value
      console.log(value)
    },
    setTreeFatherDisabled (alist) {
      alist.map(item => {
        if (item.children.length > 0) {
          item.disabled = true
          item.children = this.setTreeFatherDisabled(item.children)
        }
      })
      return alist
    },
    async queryTreeData () {
      const res = await this.$store.dispatch('http', {
        api: 'navagationTree',
        params: { navigationType: 'cms' }
      })
      res.map(item => {
        console.log(item, 'label')
      })
      const treeSetDisabled = this.setTreeFatherDisabled(res)
      console.log(res, 'treeSetDisabled')
      this.treeData = treeSetDisabled
    },
    // 图片上传 下方
    handleCancel () {
      this.previewVisible = false
    },
    async handlePreview (file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange (e) {
      console.log(e.fileList)
      this.fileList = e.fileList
    },
    handleChange3 (info, index) {
      console.log(index)
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList, 'uploading')
      }
      if (status === 'done') {
        this.form.items[index].imageUrl = info.file.response.result
        this.$store.dispatch('showToast', { type: 'success', message: '上传成功' })
      } else if (status === 'error') {
        this.$store.dispatch('showToast', { type: 'error', message: '上传失败' })
      }
    },
    handleChange2 (info, index) {
      console.log(index)
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList, 'uploading')
      }
      if (status === 'done') {
        this.form.items[index].mediaUrl = info.file.response.result
        this.$store.dispatch('showToast', { type: 'success', message: '上传成功' })
      } else if (status === 'error') {
        this.$store.dispatch('showToast', { type: 'error', message: '上传失败' })
      }
    },
    // 图处上传上方

    // 返回itemLabel
    itemLabel (resourceType) {
      switch (resourceType) {
        case 'text':
          return '文本内容'
        case 'title':
          return '标题'
        case 'img':
          return '图片'
        case 'mv':
          return '视频'
        default:
          return ' '
      }
    },
    clickBt (num) {
      if (num === 1) {
        this.$refs.contentConstituteBt1.$el.click()
      } else {
        this.$refs.contentConstituteBt2.$el.click()
      }
    },
    onSubmit () {
      this.$refs.form.validate(async valid => {
      // this.$refs.rules.validate(valid => {
        if (valid) {
          this.addContent()
        } else {
          return false
        }
      })
    },
    async addContent () {
      this.fileList.forEach((item, index) => {
        if (index === 0) {
          this.form.imageUrl = item.response.result || item.url
        }
        if (index === 1) {
          this.form.imageUrl2 = item.response.result || item.url
        }
        if (index === 2) {
          this.form.imageUrl3 = item.response.result || item.url
        }
        if (index === 3) {
          this.form.imageUrl4 = item.response.result || item.url
        }
      })
      let formData = this.$utils.clone(this.form)
      if (formData.contentConstitute === 'linkUrl') {
        delete formData.content
      }
      if (formData.contentConstitute === 'article_webview') {
        delete formData.linkUrl
      }
      let api = 'addContent'
      if (formData.id) {
        api = 'editContent'
      }
      const res = await this.$store.dispatch('http', {
        api: api,
        params: formData
      })
      if (res) {
        this.$store.dispatch('showToast', { message: (formData.id ? '编辑' : '新增') + '内容成功~' })
      }
      this.$router.go(-1)
    },
    goback () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>
.content_review_father {
    p{
        margin-right:20px;
        width:375px;
        background:#9FCEFF;
        height: 40px;
        font-size: 14px;
        text-align: center;
    }
    .content_review {
        margin-right:20px;
        width:375px;
        padding:8px;
        height:760px;
        overflow-y:scroll;
        border: 1px solid #ccc;
        ::v-deep img {
            max-width: 100%;
        }
    }
}

#content{
  max-width: 1400px;
  .content-title {
    font-size:16px;
    font-weight:bold
  }
  .content-area-title {
    font-size:16px;
    background:#fbfbfb;
    padding:10px;
  }
  .view-style-img-contain {
    display:flex;
    .view-style-img {
      width:150px;
      margin-right:7px;
      opacity: 0.2;
      cursor: pointer;
    }
    .active {
      opacity: 1;
    }
  }
  .view-style-content-contain {
    display:flex;
    .view-style-img {
      text-align:center;
      width:150px;
      line-height:16px;
      margin-right:7px;
      padding:10px 0;
      div:first-child {
        font-weight: bold;
        font-size:16px;
      }
      div:last-child {
        padding-top:10px;
        color:#aaa;
      }
    }
    .active {
      border:3px solid #eeeeee;
    }
  }
  .ant-upload-list-picture-card .ant-upload-list-item{
    width: 160px !important;
  }
}

</style>
<style>
.myupload .ant-upload-list-item {
  width: 160px;
  height: 90px;
  margin: 0;
}
.w-e-text p, .w-e-text h1, .w-e-text h2, .w-e-text h3, .w-e-text h4, .w-e-text h5, .w-e-text table, .w-e-text pre {
  margin:0;
  padding:0;
  line-height:1.8;
  color:#333;
}
.w-e-text {
  line-height:1.8;
  color:#333;
}
</style>
